<template>
  <v-list-item
    :id="`cw-business-loan-company-list__list-item__${company.businessId}`"
    :class="{ 'active': form.selectedCompany.businessId === company.businessId }"
    :ripple="{ class: 'purple-one--text text--lighten-3' }"
    :disabled="!company.valid"
    class="cw-business-loan-company-list__list-item"
    @click="form.selectedCompany = company"
  >
    <v-list-item-action>
      <v-radio
        :disabled="!company.valid"
        :value="company"
        color="purple-one darken-2"
        @click="logOnClick(
          $event,
          company,
          `cw-business-loan-company-list__radio__${company.businessId}`
        )"
      />
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>
        {{ company.companyName }}
      </v-list-item-title>
      <v-list-item-subtitle class="text-caption">
        <b><translate>Business id</translate>:</b> {{ company.businessId }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text-caption">
        <b><translate>Company type</translate>:</b> {{ company.companyType }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text-caption">
        <b><translate>Role in the Company</translate>:</b> {{ company.role }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: 'CwBusinessLoanCompanyListTile',

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    company: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    getElementData(value, id) {
      return {
        targetId: id,
        name: id,
        nodeName: 'SELECT',
        type: 'select-one',
        value: String(value),
      };
    },

    logOnClick(event, value, id) {
      const data = this.getElementData(value, id);

      this.$eventLogger.vuetifyEvent(event, data);
    },
  },
};
</script>
